.prestator-card, .prestator-card.MuiCard-root {
    background-color: var(--dark-grey);
    opacity: 0.4;
    color: var(--white);
    min-width: 10em;
    max-width: 15em;
    display: flex;
}

.prestator-card.active {
    opacity: 1;
}