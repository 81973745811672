.MuiModal-root .mandate-closure-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 20em;
    background-color: var(--primary-color);
    border-radius: 1em;

    padding: 2em;

  }