.header .content {
  text-align: center;
  align-self: center;
}

.header .content h1 {
  font-size: 3em;
  font-weight: 700;
  color: var(--title-color);
  text-transform: uppercase;
}
