.loginpage {
    background-image: url("../../assets/images/bubbleLogin.svg");
    background-repeat: no-repeat;
    width: 100%;
    min-height: 27em;
    margin-top: 6rem;
    padding-inline: 1em;
    
    flex-basis: initial !important;
    max-width: initial !important;
}

.loginpage .login-form {
    display: unset;
}

.login-form .MuiButton-root {
    padding-left: 2em;
    padding-right: 2em;
}