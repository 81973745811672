.banner-service {
    background-image: url("../../../assets/images/banner-service-backgroud.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-color: var(--dark-grey);
    min-height: 25em;
    margin: 6rem 0;
}
.banner-service .description {
    position: absolute;
    transform: translate(0%, 0%);
}