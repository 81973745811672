.sidebar .mia-logo {
    align-self: center;
}

.sidebar .sidebar-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.25rem 1rem;
    color: var(--anthracite);
}

button.sidebar-btn {
    margin: 0 2em;
    min-width: 16em;
}

.sidebar .sidebar-item.active {
    font-weight: bold;
}

@media screen and (max-width: 1024px) {
    .sidebar .logo-mia {
        display: none;
    }

    button.sidebar-btn {
        margin: 2em 2em 0em 2em;
        min-width: 16em;
    }
}