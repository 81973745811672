.homepage {
    padding: 3em;
}

.container {
    padding-inline: 1em;
}

@media (min-width: 900px) {
    .section-title {
        margin-left: 3em !important;
    }
}