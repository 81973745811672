.real-state-lawyers {
    height: auto;
    text-align: justify;
}

.real-state-lawyers .box-card {
    color: var(--dark-grey);
    box-shadow: 2px 7px 26px 3px rgba(0, 0, 0, 0.06) !important;
}

.real-state-lawyers .box-card .top {
    margin-top: 2em;
}

.real-state-lawyers .left-tab {
    height: 85em;
}

.real-state-lawyers .left-tab .box {
    height: 35em;
}

.real-state-lawyers .left-tab .description {
    margin-top: 3rem;
}

.real-state-lawyers .right-tab {
    height: auto;
}

.real-state-lawyers .container-source-img .source-img {
    background-image: url("../../../assets/images/right-tab.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 45em;
    height: 32em;
    border-radius: 0;
    margin-top: 6em;
}


.real-state-lawyers .right-tab .box {
    position: absolute;
    height: 35em;
    width: 28em;
    transform: translate(0%, 75%);
}

.citation {
    text-align: initial !important;
}

@media (min-width: 1500px) {
    .real-state-lawyers .right-tab .source-img {
        width: 55em;
        height: 37.9375em;
    }

    .real-state-lawyers .right-tab .box {
        transform: translate(20%, 90%);
    }
}

@media (max-width: 900px) {
    .real-state-lawyers .container-source-img {
        display: none !important;
    }

    .real-state-lawyers .right-tab .box {
        position: relative !important;
        height: 35em;
        width: 28em;
        transform: initial;
    }
}

@media (max-width: 600px) {
    .real-state-lawyers .right-tab .box {
        height: initial;
        width: 100%;
    }
}

@media (max-width: 900px) {
    .real-state-lawyers .left-tab, .right-tab {
        height: initial !important;
    }

    .real-state-lawyers .box {
        height: initial !important;
        width: 100% !important;
        margin: 2em 0;
    }
}