.advantages-item {
    position: relative;
    line-height: 1.5rem;
    color: var(--dark-grey);
    margin-bottom: 1.5rem;
    margin-left: 1.7rem;
  }
  
  .advantages-item .description {
    display: inline-block;
    padding-right: 0.5rem;
    font-weight: bold;
    font-size: 1.25em;
  }

  .advantages-item .description::before {
    content: '.';
    font-size: 1.5rem;
    bottom: 0.3em;
    padding-right: 0.5rem;
  }
  