.adhesion-form-page {
  position: "relative";
  padding-bottom: 20rem;
  min-height: 100vh;
}

.adhesion-form-page .MuiContainer-root {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.adhesion-form-page .field .MuiInputBase-root {
  background-color: white;
}