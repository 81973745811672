.bottom {    
    height:24.375em;
    min-height: 25em;
}

.bottom .background {
    background-image: url("../../../assets/images/bottom.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 25em;
}

.bottom .container-link, .bottom a {
    margin-bottom: 3em;
}

.bottom .container-link .link, .bottom .container-link a {
    font-size: 1em;
    line-height: 1.25em;
    text-decoration-line: underline;
    color: var(--dark-grey);
}