body, html, #root {
  height: 100%;
  scroll-behavior: smooth;
}

#root .page-content {
  overflow-y: auto;
}

body {
  --font-body: 'Helvetica Neue', sans-serif;

  /* main colors to use in priority */
  --primary-color: #fafaf9;
  --secondary-color: #E41648;
  --tertiary-color: #FFF16B;

  /* colors */
  --bright-red: #E41648;
  --dark-red: #9F1D2F;
  --dark-red-transparent: rgba(159, 29, 47, 50%);
  --yellow: #FFF16B;
  --grey: #DFDAD6;
  --dark-grey: #383737;
  --white: white;
  --black: black;
  --light-gray: rgba(223, 218, 214, 0.3);


  --title-color: var(--dark-grey);
  --sub-title-color: rgba(56, 55, 55, 70%);
  --default-color: black;
  --content-background: var(--grey);

  margin: 0;
  font-family: var(--font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: var(--anthracite);
}