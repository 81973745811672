.header {
    min-height: 12em;
}

.header .logo-container {
    justify-content: center;
}

.header .mia-logo {
    padding: 0;
}