.prestation-form .MuiAccordion-root {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.prestation-form .MuiAccordion-root .MuiAccordionSummary-root {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.prestation-form .MuiAccordionDetails-root {
  padding: 1.5em;
}

.prestation-form .MuiAlert-root + :not(.MuiAlert-root){
  margin-top: 1em;
}