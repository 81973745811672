.mandate-card .MuiCardContent-root {
    margin: 1em;
    padding: 0;
    flex-grow: 1;
}

.mandate-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.mandate-card .MuiCardActionArea-root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.mandate-card .MuiCardContent-root .title {
    margin: 0 0 0.1em 0;
}

.mandate-card .MuiCardContent-root .sub-title {
    color: var(--sub-title-color);
}

.mandate-card .MuiCardContent-root .amount {
    margin: 1em 0 0 0;
}

.mandate-card button {
    cursor: default;
}
.mandate-card a .MuiCardMedia-root {
    cursor: pointer;
}
.mandate-card .MuiCardActions-root{
    padding: 0;
}
.mandate-card .MuiCardActions-root .invoiceLink {
    margin: 1em;
    margin-top: 0;
    padding: 0;
}

.mandate-card .MuiCardContent-root > .actions {
    float: right;
}