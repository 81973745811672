.mia-adhesion-header {
  display: grid;
  grid-auto-flow: column;
}

.mia-adhesion-header .content {
  text-align: center;
  align-self: end;
}

.mia-adhesion-header .content h1 {
  font-size: 3em;
  font-weight: 700;
  color: var(--title-color);
  text-transform: uppercase;
}

.mia-adhesion-header .logo,
.mia-adhesion-header .side-action {
  width: 10rem;
}
.mia-adhesion-header .side-action {
  justify-self: end;
}

.mia-adhesion-header .tabs {
  display: flex;
  justify-content: center;
}

.mia-adhesion-header .tabs .tab {
  padding: 1rem;
}

.mia-adhesion-header .tabs .tab.selected {
  font-weight: 700;
  border-bottom: 2px solid var(--default-color);
}
