.invoice-card .MuiCardContent-root {
    margin: 0.5em;
    padding: 0;
    min-height: 4em;
}

.invoice-card .MuiCardContent-root .title {
    margin: 0 0 0.1em 0;
    padding: 0.7em 0;
}

.invoice-card .MuiCardContent-root>.actions {
    position: absolute;
    bottom: 1.7em;
    right: 0em;
}