.owners-form {
  display: flex;
  flex-direction: column;
}

.owners-form .add-more-button {
  margin: 1em 0; 
  max-width: 30em;
  align-self: center;
}

.owners-form .MuiAccordion-root {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.owners-form .MuiAccordion-root .MuiAccordionSummary-root {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.sub-form.MuiAccordionDetails-root {
  padding: 1.5em;
}