.field > label, .fields-group label {
  font-weight: 700;
  color: var(--dark-grey)
}

.field:has(input:required, textarea:required) > label::after {
  content: '*';
  margin: 0.3em;
  color: var(--bright-red);
}

.field .MuiInputBase-root, .MuiButton-root {
  border-radius: 20px;
}

.field .MuiInputBase-root {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.acceptance-field .criteria-docs {
  text-decoration: underline;
}