.contact-member {
  background: var(--dark-red);
  padding: 3em;
}

.contact-member .contact-details {
  background: white;
  max-width: 30em;
  text-align: center;
  padding: 2em;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .contact-member {
    padding: 1em 0;
  }

  .contact-member .contact-details {
    padding: 1em 0em;
  }
}