.free-benefits-item {
    display: flex;
    line-height: 1.5rem;
    color: var(--dark-grey);
    margin-bottom: 1.5rem;
  }
  
  .free-benefits-item .description {
    display: inline-block;
    margin-left: 1.2rem;
    font-size: 1em;
  }
  
  .free-benefits-item .check {
    display: inline-block;
    right: 0;
    padding-left: .2rem;
    text-align: center;
    top: 0.2rem;
    background-color: white;
  }