.order-recap .MuiTableCell-head, .order-recap .total .list-title{
  font-weight: bold;
}

.order-recap .total {
  display: flex;
  flex-direction: row-reverse;
}

.order-recap .total .MuiTable-root{
  max-width: 20em;
}

.order-recap .payment-hidden-form {
  margin-top: 3em;
}