.sidenav {
    position: absolute;
    width: 4.7em;
    height: 5em;
    padding: 0;
    margin: 0;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    z-index: 5;
    display: none;
}
.sidenav .icon-bar {
    display: block;
    width: 2em;
    height: 2px;
    margin: 5px auto;
    background-color: black;
    border-radius: 1px;
    transition: all 0.3s;
    opacity: 0.7;
}
.open.sidenav .icon-bar:nth-child(1) {
    transform: rotate(45deg);
}
.open.sidenav .icon-bar:nth-child(2) {
    opacity:0;
}
.open.sidenav .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -14px;
}

.sidebar.open {
    display: block;
}

@media screen and (max-width: 1024px) {
    .sidenav {
        display: block;
    }
}