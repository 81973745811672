.our-services-item {
  position: relative;
  line-height: 1.5em;
  color: var(--dark-grey);
  margin-bottom: 1.3em;
}

.our-services-item .description {
  background-color: white;
  display: inline-block;
  padding-right: 0.5rem;
}

.our-services-item .check {
  display: inline-block;
  position: absolute;
  right: 0;
  padding-left: .2em;
  text-align: center;
  top: 0.2em;
  background-color: white;
} 

.our-services-item .border {
  position: absolute;
  bottom: 0.4rem;
  width: 100%;
  left: 0;
  border-bottom: 2px solid #07070721;
  z-index: -1;
}
