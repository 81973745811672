.source-img {
  background-image: url("../../../assets/images/service-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 35rem;
  height: 20rem;
  border-radius: 1em;
  margin-top: 5em;
}

@media (max-width: 600px) {
  .source-img {
    width: 22rem;
  }
}

.our-services-item .description {
  margin-right: 3rem;
}

@media (max-width: 650px) {
  .our-services-item .description {
    margin-right: 0;
  }
}
