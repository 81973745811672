.our-services-work {
  margin-top: 4em;
}

.our-services-work .work-content {
  background-color: var(--content-background);
  padding: 2em;
  position: relative;
}

.our-services-work .work-image {
  background-image: url("../../../assets/images/our-service-work.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 27rem;
  height: 20rem;
  border-radius: 1em;
  margin: auto 0;
}

.our-services-work .white-rect {
  z-index: -1;
  position: absolute;
  height: 23.3em;
  top: 2em;
  left: 30em;
  width: 64%;
  background-color: var(--white);
}


.our-services-work .work-text {
  padding: 2em;
}

@media (max-width: 600px) {
  .our-services-work .work-image {
    display: none;
  }
  .our-services-work .work-text {
    background-color: var(--white);
  }
  .our-services-work .white-rect {
    display: none;
  }
}

@media (max-width: 1200px) {
  .our-services-work .work-image {
    width: 24rem;
    height: 18em;
  }
  .our-services-work .work-text {
    background-color: var(--white);
  }
  .our-services-work .white-rect {
    display: none;
  }
}
